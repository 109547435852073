<template>
	<div class="assessBox">
		<div class="app-container">
			<div class="titlebox">
				<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
				<div class="title">{{ rewardInfo && rewardInfo.name }}-奖惩详情</div>
			</div>
			<div class="picbox">
				<div>
					<el-avatar :size="100" :src="rewardInfo && rewardInfo.avatarUrl"></el-avatar>
				</div>
				<div>
					<h2>{{ rewardInfo && rewardInfo.name }}</h2>
					<p>{{ rewardInfo && rewardInfo.jobName }}&nbsp;&nbsp;{{ rewardInfo && rewardInfo.orgName }}</p>
					<p>
						<span>手机号码：{{ rewardInfo && rewardInfo.phoneNumber }}</span>
						<span>入职时间：{{ rewardInfo && rewardInfo.entryTime }}</span>
					</p>
				</div>
			</div>
			<div class="viewbox">
				<el-table :data="tableData" border style="width: 100%" class="tableBackground">
					<el-table-column prop="time" label="奖惩日期" align="center"></el-table-column>
					<el-table-column prop="typeName" label="奖惩类型" align="center"></el-table-column>
					<el-table-column prop="content" label="奖惩内容" align="center"></el-table-column>
					<el-table-column prop="reason" align="center" label="奖惩原因" min-width="200"></el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>
<script>
import * as api from '@/api/workbench';
export default {
	components: {},

	data() {
		return {
			rewardInfo: {
				default() {
					return {
						items: ''
					};
				}
			},
			tableData: []
		};
	},

	created() {},

	mounted() {
		this.$nextTick(function () {
			this.queryRewardDetails();
		});
	},
	methods: {
		queryRewardDetails() {
			api.queryRewardDetails({
				request: {
					id: this.$route.query.id
				}
			})
				.then((result) => {
					this.rewardInfo = result;
					this.tableData = result.staffRewardPunishResponseList;
				})
				.catch(() => {});
		}
	}
};
</script>
<style lang="scss" scoped>
.assessBox .app-container .viewbox .level_box_now {
	font-weight: bold;
}
.picbox {
	padding: 0px 0 0 100px;
	background: #fff;
	margin: 0 20px;
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	height: 140px;
	:first-child {
		margin-right: 10px;
	}
	h2 {
		margin: 10px 0 0 0;
	}
	p {
		margin: 15px 0px;
		color: #999999;
	}
}
.assessBox {
	.app-container {
		color: #484848;
		padding: 0;
		background-color: #f6f7fb;
		.titlebox {
			text-align: center;
			line-height: 17px;
			background: #fff;
			font: bold 18px/25px 'Microsoft YaHei';
			padding: 14px 40px;
			> .el-button {
				float: left;
				color: #484848;
				transform: translateY(-8px);
				font-size: 16px;
			}
			text-align: center;
			line-height: 17px;
			font: bold 18px/25px 'Microsoft YaHei';
			margin: 20px;
		}
		.viewbox {
			margin: 0 20px;
			background: #ffffff;
			// min-height: 300px;
			padding: 30px 50px;
			// table{
			//   border:1px solid #B0BACB
			// }
		}
	}
}
.titleIcon {
	display: inline-block;
	width: 8px !important;
	height: 20px;
	background: #498df0;
	border-radius: 5px;
	vertical-align: bottom;
	margin-right: 5px !important;
}
</style>
